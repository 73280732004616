import { useI18n } from 'vue-i18n';
import Toast from '@/components/Toast/index';
import Backend from '@/core/Services/Backend';
import { reactive, onMounted, toRefs, computed, watch, } from 'vue';
import { debounce } from 'lodash-es';
import useHistory from '@/utils/useHistory';
/**
 * 编辑批量空投页的链/币种选项hook
 * @returns
 */
const useChains = () => {
    const { t } = useI18n();
    const { add: addHistory, get: getHistory } = useHistory('tokenHistory');
    const state = reactive({
        chainTypeOptions: [],
        selectedChainType: '',
        selectedToken: '',
        selectedTokenDecimal: '',
    });
    const tokenOptions = computed(() => {
        const target = state.chainTypeOptions.find((item) => item.value === state.selectedChainType);
        const history = getHistory(state.selectedChainType);
        if (!target || !history) {
            return [];
        }
        return [...new Set([...history, target.token])];
    });
    // 切换链的时候清空token选择
    watch(() => state.selectedChainType, () => {
        state.selectedToken = '';
        state.selectedTokenDecimal = '';
    });
    const getTokenDecimal = async () => {
        state.selectedTokenDecimal = '';
        if (!state.selectedToken) {
            return;
        }
        const result = await Backend.getDecimal(state.selectedChainType, state.selectedToken);
        if (!result) {
            Toast.error(t('contract_not_found'));
            return;
        }
        state.selectedTokenDecimal = String(result);
        addHistory(state.selectedChainType, state.selectedToken);
    };
    const debouncedGetTokenDecimal = debounce(getTokenDecimal, 1000);
    // 查询精度
    watch(() => state.selectedToken, () => {
        debouncedGetTokenDecimal();
    });
    onMounted(async () => {
        const chains = await Backend.getChains();
        state.chainTypeOptions = chains.map((item) => ({
            label: item.chain,
            value: item.chainType,
            token: item.symbol,
        }));
        state.selectedChainType = state.chainTypeOptions[0].value;
    });
    return {
        ...toRefs(state),
        tokenOptions,
    };
};
export default useChains;
