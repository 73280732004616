import { computed, defineComponent, reactive, toRefs, } from 'vue';
import BaseSelect from '@/components/BaseSelect.vue';
import SelectInput from '@/components/SelectInput.vue';
import InputField from '@/components/InputField.vue';
import AddressTable from '@/views/Inc/Drop/SetDrop/AddressTable.vue';
import UploadFile from '@/components/UploadFile.vue';
import { useI18n } from 'vue-i18n';
import StepContainer from '@/components/StepContainer.vue';
import ButtonLayer from '@/components/ButtonLayer.vue';
import ContractAddressVue from '@/components/ContractAddress.vue';
import FileExample from './FileExample.vue';
import useChains from './useChains';
export default defineComponent({
    name: 'DropForm',
    components: {
        BaseSelect,
        SelectInput,
        InputField,
        AddressTable,
        UploadFile,
        FileExample,
        StepContainer,
        ButtonLayer,
        ContractAddressVue,
    },
    setup() {
        const { t } = useI18n();
        const steps = computed(() => [
            {
                title: t('drop_step1'),
                content: t('drop_step1_content'),
            },
            {
                title: t('drop_step2'),
                content: t('drop_step2_content'),
            },
            {
                title: t('drop_step3'),
                content: t('drop_step3_content'),
            },
        ]);
        const { selectedChainType, chainTypeOptions, selectedToken, tokenOptions, selectedTokenDecimal, } = useChains();
        const state = reactive({
            addressList: [],
            isManual: false,
        });
        const addressImportHandler = (addressList) => {
            state.addressList = addressList;
            state.isManual = true;
        };
        const toUpload = () => {
            state.addressList = [];
            state.isManual = false;
        };
        const toManual = () => {
            state.addressList = [
                {
                    address: '',
                    count: '',
                },
            ];
            state.isManual = true;
        };
        return {
            t,
            ...toRefs(state),
            selectedChainType,
            chainTypeOptions,
            selectedToken,
            tokenOptions,
            selectedTokenDecimal,
            addressImportHandler,
            steps,
            toUpload,
            toManual,
        };
    },
});
