import { defineComponent, ref } from 'vue';
import Dropdown from '@/components/Dropdown.vue';
import { useI18n } from 'vue-i18n';
export default defineComponent({
    name: 'FileExample',
    components: {
        Dropdown,
    },
    setup() {
        const { t } = useI18n();
        const dropdownRef = ref(null);
        const hideDrop = () => {
            if (!dropdownRef.value) {
                return;
            }
            dropdownRef.value.hide();
        };
        return {
            t,
            dropdownRef,
            hideDrop,
        };
    },
});
