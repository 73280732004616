import MultiDrop from '@/core/Interactors/MultiDrop';
import { computed, defineComponent, reactive, ref, toRefs, watch, } from 'vue';
import BaseButton from '@/components/BaseButton.vue';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import ButtonLayer from '@/components/ButtonLayer.vue';
import { crypto } from '@okexchain/javascript-sdk';
import { duplicateChecking, deleteDuplicate } from '@/utils/arrayOfObjects';
import Toast from '../../../../components/Toast';
export default defineComponent({
    name: 'AddressTable',
    components: {
        BaseButton,
        ButtonLayer,
    },
    props: {
        addressList: {
            type: Array,
            required: true,
        },
        chain: {
            type: String,
            required: true,
        },
        precision: {
            type: String,
            required: true,
        },
        tokenHash: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const { t } = useI18n();
        const state = reactive({
            list: props.addressList.map((item) => ({
                stamp: String(Math.random()),
                address: item.address,
                count: Number(item.count),
                errorType: MultiDrop.getErrorType(props.chain, item.address, item.count, Number(props.precision) || 0),
            })),
        });
        // okex链的bech32 地址列表
        // console.log(crypto.validateAddress('ex1q9nct2gska2yutx24starv6s63xz022fmf8vxk'));
        const Bech32List = computed(() => state.list.filter((item) => {
            if (crypto.validateAddress(item.address)) {
                return true;
            }
            return false;
        }));
        const Bech32ToHex = () => {
            const HexTemp = state.list.map((item) => {
                if (crypto.validateAddress(item.address)) {
                    const itemTemp = item;
                    // eslint-disable-next-line prefer-destructuring
                    itemTemp.address = crypto.convertBech32ToHex(item.address)[0];
                    itemTemp.errorType = MultiDrop.getErrorType(props.chain, item.address, String(item.count), Number(props.precision) || 0);
                    return itemTemp;
                }
                return item;
            });
            state.list = HexTemp;
        };
        // 地址格式错误的列表
        const errorList = computed(() => state.list
            .map((item, index) => ({
            ...item,
            index,
        }))
            .filter((item) => item.errorType));
        const delErrorList = () => {
            for (let i = 0; i <= state.list.length - 1; i += 1) {
                if (state.list[i].errorType) {
                    state.list.splice(i, 1);
                    i -= 1;
                }
            }
            // state.list.splice(index, 1);
        };
        // 查重的列表
        const duplicateList = computed(() => duplicateChecking(state.list, 'address'));
        // 是否保留重复项
        const isKeepDuplicate = ref(false);
        const keepDuplicate = () => {
            isKeepDuplicate.value = true;
        };
        // 删除重复的地址
        const deleteDuplicateFun = () => {
            state.list = deleteDuplicate(state.list, 'address');
        };
        const deleteLine = (index) => {
            state.list.splice(index, 1);
        };
        const addLine = () => {
            state.list.push({
                stamp: String(Math.random()),
                address: '',
                count: 0,
                errorType: 1,
            });
        };
        const checkAddress = (index) => {
            const target = state.list[index];
            target.errorType = MultiDrop.getErrorType(props.chain, target.address, String(target.count), Number(props.precision) || 0);
        };
        watch(state.list, () => {
            isKeepDuplicate.value = false;
        });
        const step = computed(() => {
            if (props.chain === 'okex' && Bech32List.value.length) {
                return 1;
            }
            if (errorList.value.length) {
                return 2;
            }
            if (duplicateList.value.length && !isKeepDuplicate.value) {
                return 3;
            }
            return 4;
        });
        const nextStep = () => {
            if (!(props.chain && props.precision && state.list.length)) {
                Toast.error(t('drop_form_invalid'));
                return;
            }
            sessionStorage.setItem('dropSet', JSON.stringify({
                addressList: state.list,
                chain: props.chain,
                precision: props.precision,
                tokenHash: props.tokenHash,
            }));
            router.push('drop/gas');
        };
        return {
            t,
            ...toRefs(state),
            deleteLine,
            addLine,
            errorList,
            delErrorList,
            duplicateList,
            isKeepDuplicate,
            keepDuplicate,
            checkAddress,
            deleteDuplicateFun,
            nextStep,
            Bech32List,
            step,
            Bech32ToHex,
        };
    },
});
